import type { ParamMatcher } from '@sveltejs/kit';

export const match: ParamMatcher = (param) => {
	const year = parseInt(param, 10);

	if (isNaN(year) || year < 2021) {
		return false;
	}

	return true;
};
