import type { ParamMatcher } from '@sveltejs/kit';

export const match: ParamMatcher = (param) => {
	const majors = ['masters', 'pga', 'us', 'open'];

	if (!majors.includes(param)) {
		return false;
	}

	return true;
};
