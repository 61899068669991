import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19')
];

export const server_loads = [2,3,4,5];

export const dictionary = {
		"/(app)": [~7,[2]],
		"/(app)/(protected)/admin": [~8,[2,3]],
		"/(app)/(protected)/entries": [~9,[2,3]],
		"/(app)/(protected)/entries/new": [~11,[2,3]],
		"/(app)/(protected)/entries/[id]": [~10,[2,3]],
		"/(auth)/forgot": [~16,[6]],
		"/(app)/leaderboard": [~12,[2,4]],
		"/(app)/leaderboard/[year=year]/overall": [~14,[2,4,5]],
		"/(app)/leaderboard/[year=year]/[major=major]": [~13,[2,4,5]],
		"/(auth)/login": [~17,[6]],
		"/(app)/logout": [~15,[2]],
		"/(auth)/register": [~18,[6]],
		"/(auth)/reset": [~19,[6]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';